import React from 'react';
import Header from '../components/HeaderComponent';
import About from '../components/AboutComponent';
import Footer from '../components/FooterComponent';

import MainPageMetadata from '../metadata/MainPageMetadata';

function AboutusPage(props) {
    return (
        <div>
            <MainPageMetadata pathname={ props.location.pathname } />
            <Header />
            <About />
            <Footer />
        </div>
    )
}

export default AboutusPage;
