import React from 'react';

function About(props) {

    return (
        <div>

            <div className="container">
                <div className="city-header">
                    <h1>About Us</h1>
                    <div className="col-12 col-sm-10 ms-auto me-auto quote-text">
                        <p><i>"It is better to see something once,<br/> than to hear about it a thousand times."</i></p>
                    </div>
                </div>

                <div className="row" style={{ marginBottom: '60px' }}>
                    <div className="col-10 col-md-5 offset-1" >
                        <div className="image-top" style={{ height: '450px', marginBottom: '20px' }}>
                            <img className="img-center-crop" width="100%" height="100%"
                               src="https://i.imgur.com/hsp7xZY.jpg" alt="About Us" />
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <p className="text-description" style={{ textAlign: 'justify' }}>
                            Welcome to <b>BondedWorld</b>! Our mission is to help <b>connect</b> you with the world, and by providing <b>guidance</b> about the various destinations around the globe,
                            we want to make it possible for <b>anyone</b>, no matter where they come from or the amount of money they have, to travel the world and experience the best of what each place has to offer.
                            <br/><br/>
                            But before all that, let us tell you a bit more about ourselves!
                            We are <b>2 friends</b> from Europe, passionate about traveling and the unknown, eager to explore what's beyond our four walls, to get out of our <b>comfort zone</b> and experience what the outside has to offer,
                            to connect and share our experiences with other <b>travellers</b> and <b>locals</b> we find during our journeys.
                            <br/><br/>
                            The thruth is, the <b>travel bug</b> bit us some years ago, and since then we have spent the <b>best</b> time of our lives chasing for the next sunshine.
                            After all we have done, we believe it has become a great opportunity for us to <b>help</b> other people experience the best of what each place has to offer.
                       </p>
                    </div>
                </div>

                <div className="row flex-column-reverse flex-md-row" style={{ marginBottom: '40px' }}>
                    <div className="col-12 col-md-5 offset-md-1">
                        <p className="text-description" style={{ textAlign: 'justify' }}>
                            With <b>BondedWorld</b>, we want to share or point of view on each destination.
                            We creat and share <b>travel guides</b> here, and they tell you just about <b>everything</b> you need to know about the place.
                            But more than tailoring an experience for a specific type, we want to show you <b>both</b> ways.
                            We will tell you to visit the <b>tourist traps</b> in the area (they are also part of the experience), but we also show you the <b>"off the beaten path"</b> places where you can truly connect with the local culture.
                            We will show you most <b>luxurious</b>, gourmet looking restaurants, and also the <b>cheap food stands</b> that every local visits.
                            <br/><br/>
                            Because, above everything, <b>BondedWorld</b> is a place for you to <b>connect</b> and <b>learn</b> about your next destination,
                            and let you decide on what best suits your taste.
                        </p>
                    </div>
                    <div className="col-12 col-md-5" style={{ marginBottom: '40px' }}>
                        <div className="border-text" style={{ textAlign: 'right' }}>
                            <p>Embrace The Adventure</p>
                        </div>
                        <div className="quote-text" style={{ textAlign: 'right', marginTop: '0px' }}>
                            Travel for the experience,<br/>
                        travel for the culture,<br/>
                            and travel to be lost...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
